import Api from './Api'

export default {
  getContent (sortBy, category, offset, time, gameName, dur, typeId, secCat, w, creator, tabGroup, fromDate, toDate) {
    return Api().get('clips',
      {
        withCredentials: true,
        params: {
          sortBy: sortBy,
          category: category,
          offset: offset,
          time: time,
          gameName: gameName,
          typeId: typeId,
          secCat: secCat,
          w: w,
          creator: creator,
          tabGroup: tabGroup,
          fromDate: fromDate,
          toDate: toDate
        }
      }
    )
  },

  getUserContent (sortBy, userId, offset, time, dur, typeId) {
    return Api().get('user/clips',
      {
        withCredentials: true,
        params: {
          sortBy: sortBy,
          userId: userId,
          offset: offset,
          time: time,
          typeId: typeId
        }
      }
    )
  },

  uploadContent (uploadURL, videoCategory, gameName, secCat, title, context, tabGroup, santa) {
    return Api().post('clips/submit',
      {
        uploadURL: uploadURL,
        videoCategory: videoCategory,
        gameName: gameName,
        secCat: secCat,
        title: title,
        context: context,
        tabGroup: tabGroup,
        santa: santa
      },
      { withCredentials: true }
    )
  },

  deleteContent (postId) {
    return Api().delete(`clips/delete/${postId}`,
      {
        withCredentials: true
      })
  },

  markWatched (postId, gameId, hide, value) {
    return Api().post('clips/watched',
      {
        postId: postId,
        gameId: gameId,
        hide: hide,
        value: value
      },
      {
        withCredentials: true
      }
    )
  },

  upvote (postId) {
    return Api().post('clips/upvote',
      {
        postId: postId
      },
      { withCredentials: true }
    )
  },

  // game id is needed to check mod status
  superUpvote (postId, gameId) {
    return Api().post('clips/superupvote',
      {
        postId: postId,
        game_id: gameId
      },
      { withCredentials: true }
    )
  },

  downvote (postId) {
    return Api().post('clips/downvote',
      {
        postId: postId
      },
      { withCredentials: true }
    )
  },

  // game id is needed to check mod status
  superDownvote (postId, gameId) {
    return Api().post('clips/superdownvote',
      {
        postId: postId,
        game_id: gameId
      },
      { withCredentials: true }
    )
  }
}
